import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { FavoriteCardComponentType, PaginationProps } from '../../../types';
import { Box, Button, useTheme } from '@mui/material';
import FavoriteCard from '../FavoriteCard';
import '@diligentcorp/atlas-web-component-icons/lib/dot/index.js';
import '@diligentcorp/atlas-web-component-icons/lib/expand-left/index.js';
import '@diligentcorp/atlas-web-component-icons/lib/expand-right/index.js';
import useScreenSize from '../../hooks/useScreenSize';
import AppConfig from '../../appConfig';
import {
  PaginationButtonContainer,
  PaginationDotsIconContainer,
  CarouselContainer,
  DotIcon,
} from './PaginationSection.styles';
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'dil-expand-left-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'dil-expand-right-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'dil-dot-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const PaginationSection = ({ FavoriteAppsData }: PaginationProps): ReactElement => {
  const [currentPage, setCurrentPage] = useState(1);
  const carouselContainerRef = useRef<HTMLDivElement>(null); // Ref for the carousel container
  const { tokens } = useTheme();
  const [totalPages, setTotalPages] = useState(1);
  const [index, setIndex] = useState({ startIndex: 0, endIndex: 0 });
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const screenSize = useScreenSize();

  const calculatePageData = () => {
    setCurrentPage(1);
    const widthOfCard = 220;
    const widthOfContainer = carouselContainerRef.current?.offsetWidth || 0;
    const tempItemsPerPage = Math.floor(widthOfContainer / widthOfCard);
    setItemsPerPage(tempItemsPerPage);
    setIndex({ startIndex: 0, endIndex: tempItemsPerPage + 1 });
    setTotalPages(Math.ceil(FavoriteAppsData.length / tempItemsPerPage));
  };

  const initiateCarouselResizeObserver = () => {
    const resizeObserver = new ResizeObserver(() => {
      calculatePageData();
    });
    resizeObserver.observe(carouselContainerRef.current as Element);
    return () => {
      resizeObserver.disconnect();
    };
  };
  useEffect(() => {
    initiateCarouselResizeObserver();
    calculatePageData();
  }, [FavoriteAppsData]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setIndex({ startIndex: index.endIndex - 1, endIndex: index.endIndex + itemsPerPage });
      setCurrentPage(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setIndex({ startIndex: index.startIndex - itemsPerPage, endIndex: index.startIndex + 1 });
      setCurrentPage(prevPage);
    }
  };

  function handleFavoriteClick(): void {
    return;
  }
  return (
    <Box data-testid="pagination-section">
      {/* Carousel container */}
      <CarouselContainer ref={carouselContainerRef}>
        {FavoriteAppsData.slice(index.startIndex, index.endIndex).map((favoritedApp) => (
          <FavoriteCard
            key={favoritedApp.appKey}
            favoriteAppData={favoritedApp}
            onFavoriteButtonClick={handleFavoriteClick}
            componentType={FavoriteCardComponentType.Default}
            data-testid="favorite-card"
          />
        ))}
      </CarouselContainer>

      {/* Pagination controls */}
      <PaginationButtonContainer data-testid="favorite-pagination-container">
        {screenSize > AppConfig.constants.mobileBreakpoint && (
          <PaginationDotsIconContainer>
            {Array.from({ length: totalPages }, (_, pageNumber) => (
              <DotIcon
                key={pageNumber}
                onClick={() => {
                  const tempIndex = { startIndex: 0, endIndex: itemsPerPage + 1 };
                  setCurrentPage(pageNumber + 1);
                  setIndex({
                    startIndex: tempIndex.startIndex + pageNumber * itemsPerPage,
                    endIndex: tempIndex.endIndex + pageNumber * itemsPerPage,
                  });
                }}
                isActive={currentPage === pageNumber + 1}
                size="small"
                role="button"
                aria-label={`dot-icon-${pageNumber + 1}`}
                data-testid="pagination-dot-icon"
              >
                <dil-dot-icon />
              </DotIcon>
            ))}
          </PaginationDotsIconContainer>
        )}

        <Box sx={{ marginLeft: 'auto' }}>
          <Button
            sx={{ width: '32px', marginRight: tokens.core.spacing['1_5'].value }}
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            size="small"
            aria-label="prev-page-button"
            data-testid="prev-page-button"
          >
            <dil-expand-left-icon />
          </Button>
          <Button
            sx={{ width: '32px' }}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            size="small"
            aria-label="next-page-button"
            data-testid="next-page-button"
          >
            <dil-expand-right-icon />
          </Button>
        </Box>
      </PaginationButtonContainer>
    </Box>
  );
};

export default PaginationSection;
