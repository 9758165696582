import React from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { getI18n, useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useCombinedGlobalNavData, CombinedData } from '../../hooks/queries/useCombinedGlobalNavData';
import { Session } from '../../types/Session';
import { GlobalNav } from '../../types/GlobalNavData';
import ErrorNotification from '../Notification/ErrorNotification';
import EmptyNotification from '../Notification/EmptyNotification';
import { AppIcon } from '@diligentcorp/atlas-web-component-global-nav/lib/react';
import './SectionTabs.scss';
import { SpinnerWrapper, StyledTilesContainer, StyledCardContent, StyledSectionTitle } from './SectionTabs.styles';
import TabContent from '../TabContent';
import { replaceKey } from '../../helpers/common';
import FavoriteAppSection from '../FavoriteAppSection';
import AppConfig from '../../appConfig';

import useScreenSize from '../../hooks/useScreenSize';

interface AppIconWrapperProps {
  appKey: any;
}

const AppIconWrapper: React.FC<AppIconWrapperProps> = ({ ...props }) => {
  return <AppIcon {...props} />;
};

function RenderTile({ title, key }: any) {
  const { presets } = useTheme();
  const screenSize = useScreenSize();
  const { ButtonTilePresets } = presets;
  const { ButtonTile } = (ButtonTilePresets as { components: any })?.components;

  return (
    <ButtonTile
      size={screenSize < AppConfig.constants.mobileBreakpoint ? 'list' : 'large'}
      className="button-tile"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
      }}
      startIcon={<AppIconWrapper appKey={key} />}
    >
      {title}
    </ButtonTile>
  );
}

function RenderSections({ data }: { data: CombinedData }) {
  const { t } = useTranslation();

  const { processedData } = data;
  const [isFavoriteSectionVisible, setFavoriteSectionVisible] = React.useState(true);

  const toggleFavoriteSection = (menuItemLabel: string) => {
    if (menuItemLabel === t('favoriteApps.menu_item_hide_favorite')) {
      setFavoriteSectionVisible(false);
    } else {
      setFavoriteSectionVisible(true);
    }
  };

  return (
    <>
      {AppConfig.featureToggles.useAppFavoritesFeature && (
        <>
          <Box>
            {
              <FavoriteAppSection
                isFavoriteSectionVisible={isFavoriteSectionVisible}
                data={data}
                toggleFavoriteSection={toggleFavoriteSection}
              />
            }
          </Box>
        </>
      )}
      {processedData.map(({ apps, key: sectionKey }) => {
        if (apps.length === 0) return null;
        return (
          <Box id={sectionKey}>
            <StyledSectionTitle variant="h2">
              {
                <div
                  className="section-title"
                  dangerouslySetInnerHTML={{ __html: t(`sections.${replaceKey(sectionKey)}`) }}
                />
              }
            </StyledSectionTitle>
            <StyledTilesContainer id={`${sectionKey}-content`}>
              {apps.map((appPresentationData: any) => (
                <StyledCardContent component="a" href={appPresentationData.url}>
                  {RenderTile(appPresentationData)}
                </StyledCardContent>
              ))}
            </StyledTilesContainer>
          </Box>
        );
      })}
    </>
  );
}

function SectionTabs({ session, globalNavData }: { session: Session; globalNavData: GlobalNav }) {
  React.useEffect(() => {
    const I18n = getI18n();
    I18n.changeLanguage(session.locale);
  }, [session.locale]);

  const { data, isFetched: isPlatformComponentsFetched, isError, isLoading } = useCombinedGlobalNavData(globalNavData);
  const { presets } = useTheme();
  const { CircularProgressPresets } = presets;
  const supportLink = data?.globalNavData?.links?.support;

  if (isLoading || !isPlatformComponentsFetched || (data && !data.isGlobalNavDataFetched)) {
    return (
      <SpinnerWrapper>
        <CircularProgress variant="indeterminate" {...CircularProgressPresets.size.lg} />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return <ErrorNotification supportLink={supportLink} />;
  }

  if (!data || !data.hasAnyApp) {
    return <EmptyNotification supportLink={supportLink} />;
  }

  return (
    <ErrorBoundary fallback={<ErrorNotification />}>
      <Box>
        <TabContent data={data} />
        <RenderSections data={data} />
      </Box>
    </ErrorBoundary>
  );
}

export default SectionTabs;
