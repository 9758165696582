import React, { useState, FC, ReactElement, useContext, useEffect } from 'react';
import type { LensTheme } from '@diligentcorp/atlas-theme-mui/lib/themes/lens/types';
import { Button, Drawer, useTheme, Box } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import SwitchComponent from '../SwitchComponent/SwitchComponent';
import FavoriteSortableApps from '../FavoriteSortableApps';
import { useTranslation } from 'react-i18next';
import AppConfig from '../../appConfig';
import { CombinedData } from '../../hooks/queries/useCombinedGlobalNavData';
import FavoriteApps from '../FavoriteApps/FavoriteApps';
import { FavoriteAppData } from '../../../types';
import { HeaderDivider, FooterButtonContainer } from './FavoriteAppSettings.style';
import useScreenSize from '../../hooks/useScreenSize';
import { FavoriteAppSectionContext } from '../FavoriteAppSection/FavoriteAppSection';
import { updateUserPreference } from '../../api/handlers/usePreferences';
import { UserPreferences } from '../../types/UserPreferences';
interface PopupDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  appKey?: string;
  data: CombinedData;
  onSaveFavoriteApps: () => void;
}

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

interface TabContentProps {
  data: CombinedData;
  sortableApps: FavoriteAppData[];
  handleSortableApps: (apps: FavoriteAppData[]) => void;
  handleFavoriteButtonClicked: (appData: FavoriteAppData) => void;
}

const RenderTabs = ({ data, sortableApps, handleSortableApps, handleFavoriteButtonClicked }: TabContentProps) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(0);

  const handleChange = (_event: React.ChangeEvent<unknown>, newValue: any) => {
    setSelectedValue(newValue);
  };

  return (
    <Box>
      <Tabs onChange={handleChange} value={selectedValue} aria-label="favorite-tabs">
        <Tab label={t('favoriteApps.tab_item_favorite')} key="tab-favorite" />
        <Tab label={t('favoriteApps.tab_item_section_reorder')} key="tab-SectionOrder" />
      </Tabs>
      <TabPanel value={selectedValue} index={0}>
        {AppConfig.featureToggles.showFavoriteSwitch && (
          <>
            <SwitchComponent></SwitchComponent>
            <HeaderDivider></HeaderDivider>
          </>
        )}

        <FavoriteSortableApps
          sortableApps={sortableApps}
          handleSortableApps={handleSortableApps}
          handleFavoriteButtonClicked={handleFavoriteButtonClicked}
        />
        <FavoriteApps
          data={data}
          sortableApps={sortableApps}
          handleFavoriteButtonClicked={handleFavoriteButtonClicked}
        />
      </TabPanel>
      <TabPanel value={selectedValue} index={1}>
        <></>
      </TabPanel>
    </Box>
  );
};

const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return value === index && <Box>{children}</Box>;
};

const FavoriteSection: FC<PopupDialogProps> = ({ open, onClose, title, data, onSaveFavoriteApps }): ReactElement => {
  const theme = useTheme<LensTheme>();
  const favoriteContextData = useContext(FavoriteAppSectionContext);
  const [sortableApps, setsortableApps] = useState<FavoriteAppData[]>([]);
  const SideSheetPresets = theme.presets.SideSheetPersets;
  const { components } = SideSheetPresets;
  const { Header, Footer, Content } = components;
  const { t } = useTranslation();
  const screenSize = useScreenSize();

  const handleSortableApps = (sortApps: FavoriteAppData[]) => {
    setsortableApps(sortApps);
  };

  const handleFavoriteButtonClicked = (appData: FavoriteAppData) => {
    //check if its already present in  sortable apps, if yes remove it
    const app = sortableApps.find((app: FavoriteAppData) => app.appKey === appData.appKey);
    if (app) {
      const newApps = sortableApps.filter((app: FavoriteAppData) => app.appKey !== appData.appKey);
      handleSortableApps(newApps);
    } else {
      handleSortableApps([...sortableApps, appData]);
    }
  };

  const saveFavoriteApps = async () => {
    // // Implement the save functionality and api call
    try {
      const requiredFavoriteAppFields = sortableApps.map((app) => {
        return {
          appKey: app.appKey,
          sectionKey: app.sectionKey,
        };
      });
      await updateUserPreference({ appFavorites: requiredFavoriteAppFields, sectionFavorites: [] } as UserPreferences);
      favoriteContextData.setFavoriteAppsList(sortableApps);
      onSaveFavoriteApps();
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setsortableApps(favoriteContextData.favoriteAppsList);
  }, [favoriteContextData.favoriteAppsList]);

  const renderSideSheetContent = (
    <>
      <Header
        onClose={onClose}
        titleText={title}
        closeButtonProps={{ 'aria-label': 'Close side sheet', 'aria-controls': 'sideSheetId' }}
      />
      <Content>{RenderTabs({ data, sortableApps, handleSortableApps, handleFavoriteButtonClicked })}</Content>
      <Footer data-testid="favorite-sidebar-footer">
        <FooterButtonContainer>
          <Button variant="text" onClick={onClose} size="medium">
            {t('favoriteApps.sidebar_close_button_label')}
          </Button>
          <Button onClick={saveFavoriteApps} variant="contained" color="primary" size="medium">
            {t('favoriteApps.sidebar_done_button_label')}
          </Button>
        </FooterButtonContainer>
      </Footer>
    </>
  );
  function getPopUpWidth() {
    const isMobile = screenSize < AppConfig.constants.mobileBreakpoint;
    return isMobile ? '100%' : '430px';
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      data-testid="favorite-sidebar-drawer"
      PaperProps={{
        sx: { minWidth: getPopUpWidth() },
      }}
    >
      {renderSideSheetContent}
    </Drawer>
  );
};

export default FavoriteSection;
