import * as api from '../../api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserPreferences } from '../../types/UserPreferences';
export function useUserPreference(): UseQueryResult<UserPreferences> {
  return useQuery(['userPreferences'], () => api.getUserPreferences(), {
    select: (data) => {
      return data;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
